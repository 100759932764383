import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const ArticleFooter = ({ path }) => (
  <footer>
    <Link rel="full-article" to={path}>
      Read on →
    </Link>
  </footer>
)

ArticleFooter.propTypes = {
  path: PropTypes.string,
}

ArticleFooter.defaultProps = {
  path: ``,
}

export default ArticleFooter
